<script>
export default {
  mounted() {
    this.fetchPMY();
    this.fetchLocations();
  },
  data() {
    return {
      selectPeriod: false,
      pmyMonth: null,
      pmyYear: null,
      pmyDate: null,
      useCurrent: false,
      location: null,
      locations: [{ value: null, text: "Select location" }],
    };
  },
  methods: {
    fetchPMY() {
      this.apiGet(
        this.ROUTES.payrollMonthYear,
        "Get Payroll Month & Year Error"
      ).then((res) => {
        if (res.data) {
          const { pym_year, pym_month } = res.data;
          this.pmyMonth = pym_month;
          this.pmyYear = pym_year;
        }
      });
    },
    fetchLocations() {
      this.apiGet(this.ROUTES.location, "Get Locations Error").then((res) => {
        this.locations = [
          { value: null, text: "Select location" },
          { value: 0, text: "All locations" },
        ];
        const { data } = res;
        data.forEach((location) => {
          this.locations.push({
            value: location.location_id,
            text: `${location.l_t6_code} (${location.location_name})`,
          });
        });
      });
    },
    async generate() {
      let data, month, year;
      if ((!this.useCurrent && !this.pmyDate) || this.location === null) {
        this.apiFormHandler("Timesheet Report");
      } else {
        let date = this.pmyDate.split("-");
        month = parseFloat(date[1]);
        year = parseFloat(date[0]);
        data = {
          month,
          year,
          location: this.location,
        };
      }
      const url = `${this.ROUTES.timeAllocation}/timesheet-application-tracking-report`;
      this.apiPost(url, data, "Generate Leave Tracker Report").then((res) => {
        if (res) {
          const { data } = res
          //console.log(data);
          if (data.salaryEmployees.length) {
            this.$router.push({
              name: "timesheet-tracker-report",
              params: {
                period: `${month}-${year}`,
                locationID: this.location,
              },
            });
          }
        }
      });
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="media">
        <div class="media-body overflow-hidden">
          <h4 class="mb-0">Timesheet Tracking Report</h4>
        </div>
        <div class="text-secondary"></div>
      </div>
    </div>
    <div class="card-body border-top py-2">
      <div
        class="d-flex align-items-center text-success d-inline-flex"
        style="cursor: pointer"
        @click="selectPeriod = true"
      >
        <span class="mr-2">Generate</span>
        <i class="ri-arrow-right-s-line"></i>
      </div>
    </div>
    <b-modal
      v-model="selectPeriod"
      title="Generate Timesheet Report"
      hide-footer
      centered
      title-class="font-18"
      size="md"
      no-close-on-backdrop
      no-close-on-esc
    >
      <form @submit.prevent="generate">
        <div class="form-group" v-if="!useCurrent">
          <label for="pmy">
            Select Period <span class="text-danger">*</span>
          </label>
          <input id="pmy" v-model="pmyDate" type="month" class="form-control" />
        </div>
        <div class="form-group">
          <label for="">Select Location</label>
          <b-form-select v-model="location" :options="locations" />
        </div>
        <b-button
          v-if="!submitting"
          class="btn btn-success btn-block mt-4"
          type="submit"
        >
          Submit
        </b-button>
        <b-button
          v-else
          disabled
          class="btn btn-success btn-block mt-4"
          type="submit"
        >
          Submitting...
        </b-button>
      </form>
    </b-modal>
  </div>
</template>
