<script>
export default {
  mounted() {
    this.fetchFYs();
    this.fetchLocations();
  },
  data() {
    return {
      selectPeriod: false,
      pmyMonth: null,
      pmyYear: null,
      pmyDate: null,
      useCurrent: false,
      location: null,
      locations: [{ value: null, text: "Select location" }],
      stage:null,
      fys:[],
      fy:null,
      stages: [],
    };
  },
  methods: {
    fetchPMY() {
      this.apiGet(
        this.ROUTES.payrollMonthYear,
        "Get Payroll Month & Year Error"
      ).then((res) => {
        if (res.data) {
          const { pym_year, pym_month } = res.data;
          this.pmyMonth = pym_month;
          this.pmyYear = pym_year;
        }
      });
    },
    fetchFYs() {
      this.apiGet(
        `${this.ROUTES.selfAssessment}/get-all-fys`,
        "Error Fetching FYs"
      ).then((res) => {
        if (res.data) {
           const fys = res.data;
           //console.log(fys)
           fys.map(fy=>{
             this.fys.push(fy.sam_year)
           });
        }
      });
    },
    fetchLocations() {
      this.apiGet(this.ROUTES.location, "Get Locations Error").then((res) => {
        this.locations = [
          { value: null, text: "Select location" },
          { value: 0, text: "All locations" },
        ];
        const { data } = res;
        data.forEach((location) => {
          this.locations.push({
            value: location.location_id,
            text: `${location.l_t6_code} (${location.location_name})`,
          });
        });
      });
    },
    async generate() {
      let data;
        data = {
          fy:this.fy,
          location: this.location,
          gs_id:this.stage
        };
      const url = `${this.ROUTES.selfAssessment}/self-assessment-tracking-report`;
      this.apiPost(url, data, "Generate Self-assessment Report").then((res) => {
        if (res) {
          const { assessments, location, fy } = res.data
          if (assessments.length) {
            this.$router.push({
              name: "self-assessment-tracker-report",
              params: {
                stage: this.stage, //gs_id
                fy: fy,
                location: location,
              },
            });
          }

        }
      });
    },
    async selectedFY(){
      let fy = this.fy;
      if(fy !== ''){
        const url = `${this.ROUTES.goalSetting}/get-goal-setting-by-fy/${fy}`;
        this.apiGet(url, "Whoops! Error getting goal year").then((res) => {
          if (res) {
            const { data } = res;
            this.stages = [];
            data.goalYears.map(gs=>{
              let text = '';
              if(parseInt(gs.gs_activity) === 1){
                text = "Beginning of Year"
              }else if(parseInt(gs.gs_activity) === 2){
                text = "Mid-year"
              }else if(parseInt(gs.gs_activity) === 3){
                text = "End of Year";
              }
              const obj = {
                value: gs.gs_id,
                text: text
              }
              this.stages.push(obj);
            });

          }
        });
      }
    }
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="media">
        <div class="media-body overflow-hidden">
          <h4 class="mb-0">Self-assessment Report</h4>
        </div>
        <div class="text-secondary"></div>
      </div>
    </div>
    <div class="card-body border-top py-2">
      <div
        class="d-flex align-items-center text-success d-inline-flex"
        style="cursor: pointer"
        @click="selectPeriod = true"
      >
        <span class="mr-2">Generate</span>
        <i class="ri-arrow-right-s-line"></i>
      </div>
    </div>
    <b-modal
      v-model="selectPeriod"
      title="Generate Self-assessment Report"
      hide-footer
      centered
      title-class="font-18"
      size="md"
      no-close-on-backdrop
      no-close-on-esc
    >
      <form @submit.prevent="generate">
        <div class="form-group">
          <label for="">Select Location</label>
          <b-form-select v-model="location" :options="locations" />
        </div>
        <div class="form-group">
          <label for="">Select FY</label>
          <b-form-select v-on:change="selectedFY()" v-model="fy" :options="fys" />
        </div>
        <div class="form-group">
          <label for="">Select Stage</label>
          <b-form-select v-model="stage" :options="stages" />
        </div>
        <b-button
          v-if="!submitting"
          class="btn btn-success btn-block mt-4"
          type="submit"
        >
          Submit
        </b-button>
        <b-button
          v-else
          disabled
          class="btn btn-success btn-block mt-4"
          type="submit"
        >
          Submitting...
        </b-button>
      </form>
    </b-modal>
  </div>
</template>
